<template>
  <div>
    <v-dialog v-model="visible" width="572">
      <v-card>
        <v-card-title class="text-h5">
          Publish Live Event - {{ data.date }}
        </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <div class="mt-2.5">
              <v-autocomplete
                label="Choose an event"
                outlined
                v-model="selected_event"
                :items="
                  events.map((x) => ({
                    ...x,
                    shownLabel: `${x.name} (${x.alias})`,
                  }))
                "
                dense
                hide-details
                class="text-[13px]"
                return-object
                item-text="shownLabel"
              ></v-autocomplete>
            </div>
            <div class="mt-5 flex items-center gap-2.5">
              <div class="w-full">
                <v-menu
                  v-model="start_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="text-[13px]"
                      v-model="start_at"
                      label="Start At"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_at"
                    @input="start_date_menu = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <div class="mt-2.5 flex items-center gap-2.5">
              <div class="w-full">
                <v-menu
                  v-model="end_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="text-[13px]"
                      v-model="end_at"
                      label="Finish At"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_at"
                    @input="end_date_menu = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <div v-if="start_at && end_at" class="mt-1.5 text-right">
              Duration:
              {{
                daysDifference >= 0 ? Math.round(daysDifference / 86400) : "-"
              }}
              days.
            </div>

            <div class="mt-1" v-if="mode === 'insert'">
              <v-checkbox
                v-model="isRecurring"
                hide-details
                label="Publish as recurring"
                :style="{ marginTop: 0 }"
              ></v-checkbox>
            </div>

            <div v-if="isRecurring" class="mt-2.5">
              <div>
                <v-select
                  label="Amount"
                  outlined
                  v-model="recurringOptions.amount"
                  :items="Array.from({ length: 29 }, (_, i) => i + 2)"
                  dense
                  hide-details
                  class="text-[13px]"
                ></v-select>
              </div>
              <div class="flex items-center gap-2 mt-2.5">
                <div class="w-full">
                  <v-text-field
                    v-model="recurringOptions.period.value"
                    label="Number of days between events"
                    outlined
                    dense
                    hide-details
                    type="number"
                    class="text-[13px]"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="error" text @click="visible = false"> Cancel </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="isBtnDisabled"
              @click="submitChanges"
              :loading="loadingBtn"
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      selected_event: null,
      visible: false,
      eventData: null,
      start_date_menu: null,
      start_time_menu: null,
      end_date_menu: null,
      start_at: null,
      end_at: null,
      loadingBtn: false,
      isRecurring: false,
      recurringOptions: {
        amount: 2,
        period: {
          mode: "Days",
          value: null,
        },
      },
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "insert",
    },
    games: {
      type: Array,
      default: () => [],
    },
    eventTypes: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.eventData = null;
        this.start_date_menu = null;
        this.end_date_menu = null;
        this.end_date_menu = null;
        this.start_at = null;
        this.end_at = null;
        this.isRecurring = false;
        this.recurringOptions = {
          amount: 2,
          period: {
            mode: "Days",
            value: null,
          },
        };
      }
    },
    data: {
      handler(newValue) {
        if (newValue) {
          this.start_at = newValue.date;
        }
      },
      deep: true,
    },
  },
  computed: {
    computedDate() {
      return moment().format("YYYY-MM-DD");
    },
    daysDifference() {
      if (this.start_at && this.end_at) {
        const end_at_unix = moment(this.end_at, "YYYY-MM-DD")
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .utc(true)
          .valueOf();

        const start_at_unix = moment(this.start_at, "YYYY-MM-DD")
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .utc(true)
          .valueOf();

        return (
          Math.round(end_at_unix / 1000) - Math.round(start_at_unix / 1000)
        );
      }
      return null;
    },
    isBtnDisabled() {
      if (!this.selected_event) return true;
      if (!this.start_at) return true;
      if (!this.end_at) return true;
      if (!this.data) return true;

      return false;
    },
  },
  methods: {
    isValidTime(time) {
      // Split the input time into hours and minutes
      const [hours, minutes] = time.split(":").map(Number);

      // Check if the input format is valid
      if (
        isNaN(hours) ||
        isNaN(minutes) ||
        hours < 0 ||
        minutes < 0 ||
        hours >= 24 ||
        minutes >= 60
      ) {
        return false;
      }

      // If all checks pass, the time is considered valid
      return true;
    },
    deleteLiveEvent() {
      this.$emit("delete", this.eventData.id);
      this.visible = false;
    },
    getTimeObject(time) {
      // Split the input time into hours and minutes
      const [hours, minutes] = time.split(":").map(Number);

      // Check if the input format is valid
      if (
        isNaN(hours) ||
        isNaN(minutes) ||
        hours < 0 ||
        minutes < 0 ||
        hours >= 24 ||
        minutes >= 60
      ) {
        return "Invalid input format";
      }

      // Create and return the object with "hour" and "minute" properties
      return {
        hour: hours,
        minute: minutes,
      };
    },
    dateToUnix(date, hour, minute, second, mil) {
      return moment(date, "YYYY-MM-DD")
        .set({
          hour: hour,
          minute: minute,
          second: second,
          millisecond: mil,
        })
        .utc(false)
        .valueOf();
    },
    submitChanges() {
      // const start_hours_obj = this.getTimeObject(this.start_at_hours);
      // const start_at_unix = this.dateToUnix(
      //   this.start_at,
      //   start_hours_obj.hour,
      //   start_hours_obj.minute,
      //   0,
      //   0
      // );

      // const end_hours_obj = this.getTimeObject(this.end_at_hours);
      // const end_at_unix = this.dateToUnix(
      //   this.end_at,
      //   end_hours_obj.hour,
      //   end_hours_obj.minute,
      //   0,
      //   0
      // );

      // if (start_at_unix > end_at_unix) {
      //   return;
      // }

      const requestData = {
        event_id: this.selected_event.id,
        event_name: this.selected_event.name,
        alias: this.selected_event.alias,
        start_date: `${this.start_at} 00:00`,
        end_date: `${this.end_at} 00:00`,
        isRecurring: this.isRecurring,
        recurringOptions: this.recurringOptions,
      };

      if (this.mode === "update")
        this.$emit("update", { id: this.eventData.id, ...requestData });
      if (this.mode === "insert") this.$emit("insert", requestData);
      this.visible = false;
    },
  },
};
</script>

<style lang="scss"></style>
