<template>
  <div
    class="bg-white sidebar-section"
    :class="windowWidth >= 1024 ? 'sidebar-large-section' : ''"
  >
    <v-navigation-drawer
      width="270px"
      floating
      :permanent="windowWidth > 1024"
      :temporary="windowWidth <= 1024"
      :absolute="windowWidth <= 1024"
      v-model="show_drawer"
    >
      <v-list dense shaped>
        <v-list-item color="primary" link :to="{ name: 'home' }" exact>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Admin -->

        <v-list-item
          link
          :to="{ name: 'config' }"
          v-if="getUserProfile.department.id === departments.ADMIN"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'Config'"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'audit-logs-view' }"
          v-if="getUserProfile.department.id === departments.ADMIN"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'Audit Logs'"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-database-search</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'users' }"
          v-if="getUserProfile.department.id === departments.ADMIN"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'Manage Users'"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <!-- /// -->

        <v-list-group
          :value="false"
          no-action
          v-if="
            getUserProfile.tools.some((x) => x.id === tools.GAME_BACKEND) ||
            getUserProfile.department.id === departments.ADMIN
          "
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Game Backend'"></v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- <v-list-item
            link
            :to="{ name: 'game-backend-projects' }"
            v-if="getUserProfile.department.id === departments.ADMIN"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Projects (Tamamlanmadi)'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- <v-list-item link :to="{ name: 'tokens-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Tokens'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item link :to="{ name: 'event-calendar-dev-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Calendar (Dev)'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'event-calendar-test-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Calendar (Test)'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'event-calendar-prod-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Calendar (Prod)'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'events-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Events'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'experiments-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Experiments'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- CCC (Deprecated) -->
        <!-- <v-list-group
          v-if="
            getUserProfile.tools.some(
              (x) =>
                x.id === tools.FACEBOOK_CAMPAIGN_CREATOR ||
                x.id === tools.TIKTOK_CAMPAIGN_CREATOR
            )
          "
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Concept Campaign Creator'"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link :to="{ name: 'ccc-create-game' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Create Game'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            no-action
            :value="false"
            sub-group
            v-if="
              getUserProfile.tools.some(
                (x) => x.id === tools.FACEBOOK_CAMPAIGN_CREATOR
              )
            "
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>CPI Test</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item link :to="{ name: 'fb-campaign-create-view' }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Create Facebook Campaign'"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group
            no-action
            :value="false"
            sub-group
            v-if="
              getUserProfile.tools.some(
                (x) => x.id === tools.TIKTOK_CAMPAIGN_CREATOR
              )
            "
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>CPC Test</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item link :to="{ name: 'tk-create-store-page-view' }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Create Store Page'"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'tk-update-store-page-view' }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Update Store Page'"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'tk-create-campaign-view' }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Create TikTok Campaign'"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group> -->
        <!-- /// -->

        <!-- Campaign Manager -->
        <v-list-group
          :value="false"
          no-action
          v-if="
            getUserProfile.tools.some((x) => x.id === tools.CAMPAIGN_MANAGER)
          "
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Burcha'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            link
            :to="{ name: 'campaign-manager-all-campaigns-view' }"
          >
            <v-list-item-content>
              <v-list-item-title v-text="'All Campaigns'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'campaign-manager-create-playable-view' }"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Create Playable Asset'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'campaign-manager-upload-google-image-view' }"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Upload Google Image'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- /// -->

        <!-- ACT -->
        <v-list-group
          v-if="getUserProfile.tools.some((x) => x.id === tools.ACT)"
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Automatized Creative Testing'"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- UA Spesiyalist -->
          <v-list-item
            link
            :to="{ name: 'act-network-configuration' }"
            v-if="
              [
                departments.ADMIN,
                departments.UA_TEAM,
                departments.DATA_TOOL,
              ].includes(getUserProfile.department.id)
            "
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Ad Network Config'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :to="{ name: 'act-drive-settings' }"
            v-if="
              [departments.ADMIN, departments.UA_TEAM].includes(
                getUserProfile.department.id
              )
            "
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'External Drive Settings'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Marketing Artist -->
          <v-list-item
            link
            :to="{ name: 'act-upload-assets-view' }"
            v-if="
              [departments.ADMIN, departments.MARKETING_ARTISTS].includes(
                getUserProfile.department.id
              )
            "
          >
            <v-list-item-content>
              <v-list-item-title v-text="'Upload Assets'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'act-manage-assets-view' }"
            v-if="
              [
                departments.ADMIN,
                departments.MARKETING_ARTISTS,
                departments.UA_TEAM,
                departments.DATA_TOOL,
              ].includes(getUserProfile.department.id)
            "
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Manage Creatives'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :to="{ name: 'act-creative-insights-view' }"
            v-if="
              [
                departments.ADMIN,
                departments.MARKETING_ARTISTS,
                departments.UA_TEAM,
                departments.DATA_TOOL,
              ].includes(getUserProfile.department.id)
            "
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Creative Insights'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- /// -->

        <!-- ACG -->
        <v-list-group
          v-if="
            getUserProfile.tools.some((x) => x.id === tools.ACG) ||
            [departments.ADMIN].includes(getUserProfile.department.id)
          "
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Automatized Creative Generator'"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            :to="{ name: 'acg-upload-build-view' }"
            v-if="[departments.ADMIN].includes(getUserProfile.department.id)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="'Upload Build'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'acg-create-play-record-view' }">
            <v-list-item-content>
              <v-list-item-title
                v-text="'Create Base Video'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'acg-import-ae-project-view' }">
            <v-list-item-content>
              <v-list-item-title
                v-text="'Import AE Project'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'acg-create-combination-view' }">
            <v-list-item-content>
              <v-list-item-title
                v-text="'Create Combination'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            no-action
            :value="false"
            sub-group
            v-if="[departments.ADMIN].includes(getUserProfile.department.id)"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Drill & Collect</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item link :to="{ name: 'acg-drill-collect-upload-level' }">
              <v-list-item-content>
                <v-list-item-title v-text="'Upload Level'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group>
        <!-- /// -->

        <!-- Icon & Screenshot Tester -->
        <v-list-group
          v-if="getUserProfile.tools.some((x) => x.id === tools.PERSEPHONE)"
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Icon & Screenshot Tester'"
              ></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link :to="{ name: 'icon-tester-config-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Configuration'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'icon-tester-create-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Create Test'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'icon-tester-result-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Results'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- /// -->

        <!-- Utils -->
        <v-list-group
          v-if="
            [departments.ADMIN].includes(getUserProfile.department.id) ||
            getUserProfile.tools.some(
              (x) => x.id === tools.STREAMLIT || tools.IAP_PRICE_OPERATOR
            )
          "
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Utility Tools'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            link
            :to="{ name: 'utility-streamlit' }"
            v-if="getUserProfile.tools.some((x) => x.id === tools.STREAMLIT)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="'Streamlit'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'utility-soft-launch-ltv-calculator' }"
            v-if="getUserProfile.tools.some((x) => x.id === tools.STREAMLIT)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Soft Launch LTV Calculator'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :to="{ name: 'adjust-iap-pricing-ui' }"
            v-if="
              getUserProfile.tools.some(
                (x) => x.id === tools.IAP_PRICE_OPERATOR
              )
            "
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'IAP Price Operator'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- /// -->

        <!-- Utils -->
        <v-list-group
          v-if="getUserProfile.tools.some((x) => x.id === tools.APPLOVIN)"
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Applovin'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            link
            :to="{ name: 'applovin-waterfall' }"
            v-if="getUserProfile.tools.some((x) => x.id === tools.APPLOVIN)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Waterfall Creator'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'applovin-placement-refresher' }"
            v-if="getUserProfile.tools.some((x) => x.id === tools.APPLOVIN)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Placement Refresher'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'applovin-max-wf-operator' }"
            v-if="getUserProfile.tools.some((x) => x.id === tools.APPLOVIN)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="'Max WF Operator'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'applovin-max-wf-operator-xlsx' }"
            v-if="getUserProfile.tools.some((x) => x.id === tools.APPLOVIN)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Max WF Operator [XLSX]'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- /// -->

        <!-- Parasut -->
        <v-list-group
          :value="false"
          no-action
          v-if="getUserProfile.tools.some((x) => x.id === tools.PARASUT)"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Parasut'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link :to="{ name: 'create-template-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Taslak Oluştur'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'manage-templates-view' }">
            <v-list-item-content>
              <v-list-item-title
                v-text="'Taslakları Yönet'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'create-invoice-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Fatura Oluştur'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'all-invoices-view' }">
            <v-list-item-content>
              <v-list-item-title
                v-text="'Faturaları Yönet'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- /// -->

        <!-- Firebase Stuff -->
        <v-list-group
          :value="false"
          no-action
          v-if="
            getUserProfile.tools.some((x) => x.id === tools.FIREBASE_AB_TESTS)
          "
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Firebase AB Tests'"
              ></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link :to="{ name: 'create-form-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Create Detail'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'all-forms-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'List Tests'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- CPA SHEET -->
        <v-list-group
          v-if="getUserProfile.tools.some((x) => x.id === tools.CPA_SHEET)"
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Cpa Sheet'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            link
            :to="{ name: 'conversion-event-creator' }"
            v-if="
              [departments.DATA_TOOL, departments.ADMIN].includes(
                getUserProfile.department.id
              )
            "
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="'Create Conversion Event'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'cpa-log-modifier-view' }">
            <v-list-item-content>
              <v-list-item-title v-text="'Cpa Sheet Data'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- /// -->
        <!-- /// -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { departments } from "@/helper/departments";
import { tools } from "@/helper/tools";

export default {
  name: "CustomSidebar",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      show_drawer: true,
      departments,
      tools,
    };
  },
  watch: {
    windowWidth: {
      handler(newValue) {
        if (newValue <= 1024) {
          this.show_drawer = false;

          if (this.drawer) {
            this.show_drawer = true;
          }
        }
      },
      immediate: true,
    },
    drawer(newValue) {
      this.show_drawer = newValue;
    },
    show_drawer(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  computed: {
    ...mapGetters(["getUserProfile"]),
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-large-section {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
  z-index: 5;
}
.sidebar-section {
  .v-navigation-drawer .v-list-item .v-list-item__title {
    color: hsla(0, 0%, 43.1%, 0.8);
  }
  .v-navigation-drawer .v-list-item:not(.v-list-item--active) .v-icon {
    color: hsla(0, 0%, 43.1%, 0.8);
  }
  .v-navigation-drawer
    .v-list-item--active
    .v-list-item__content
    .v-list-item__title {
    color: #1976d2;
  }
  .v-list-item--dense,
  .v-list--dense .v-list-item {
    min-height: 45px;
  }
}
</style>
