<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card v-if="trigger && message">
        <v-card-title class="text-h5">
          Confirm Operation - {{ title }}</v-card-title
        >

        <v-card-text>
          <div class="mt-5 text-[12px]" v-html="message"></div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="red darken-1" text @click="visible = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="$emit('submit', trigger)">
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
    trigger: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
